import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import React from 'react';

import { Button } from '@components/Button/Button';
import { showLiveChat } from '@containers/LiveChat/LiveChat';
import { AuthContainer } from '@hooks/authContainer';
import { externalRoutes, routes } from '@routes';

import styles from './SideLinks.module.scss';
import ArrowRightIcon from '@icons/arrowRight.svg';

interface IProps {
  onCloseMenu: () => void;
}

const cx = classNames.bind(styles);

export const SideLinks: React.FC<IProps> = ({ onCloseMenu }) => {
  const { authState } = AuthContainer.useContainer();
  const [intercomReady, setIntercomReady] = React.useState(false);
  const { pathname } = useRouter();

  React.useEffect(() => {
    // We don't know when Intercom intializes, let's wait for it using setInterval
    const intercomBootInterval = setInterval(() => {
      if (typeof window.Intercom === 'function') {
        setIntercomReady(true);
        clearInterval(intercomBootInterval);
      }
    }, 300);

    return () => clearInterval(intercomBootInterval);
  }, []);

  return (
    <div className={cx('wrapper', { isVisible: authState.isUserDataLoaded })}>
      {authState.id ? (
        <>
          {pathname !== routes.nab && (
            <Button
              className={styles.button}
              disabled={!intercomReady}
              id="open-chat"
              size="md"
              theme="button"
              onClick={() => {
                showLiveChat();
                onCloseMenu();
              }}
            >
              24/7 Support
            </Button>
          )}
          <Button
            className={styles.button}
            href={externalRoutes.client.dashboard}
            icon={<ArrowRightIcon />}
            size="md"
            theme="button"
            verticalAlign="center"
            onClick={onCloseMenu}
          >
            Client Panel
          </Button>
        </>
      ) : (
        <>
          <Button className={styles.link} href={externalRoutes.client.login} external noIcon>
            Login
          </Button>
          <div className={styles.buttonsWrapper}>
            {pathname !== routes.nab && (
              <Button
                className={styles.button}
                disabled={!intercomReady}
                id="open-chat"
                size="md"
                theme="button"
                variant="secondary"
                onClick={() => {
                  showLiveChat();
                  onCloseMenu();
                }}
              >
                24/7 Support
              </Button>
            )}
            <Button
              className={styles.button}
              href={routes.getStarted}
              id="sign-up"
              size="md"
              theme="button"
              onClick={onCloseMenu}
            >
              Get started
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
